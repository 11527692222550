.document-overview__toolbar {
  width: 100%;
  background: #fafafa;
  max-width: 100%;
  z-index: 3;
  padding-top: 0;
  top: 0;
}

:global(#toolbarWrapper:has(.ck-dropdown__panel-visible)) {
  z-index: 1001 !important;
}

.document-overview__toolbar :global .ck.ck-toolbar_grouping {
  width: 100%;
  background: var(--chakra-colors-gray-50);
  border: 1px var(--chakra-colors-gray-200) solid;
  border-radius: 4px 4px 0 0;
  box-shadow: none;
  margin-top: 0;
  margin-bottom: 0;
}

:global(.document-edit-suggest-toggle > div) {
  z-index: 10002 !important;
}

.document-overview-tabs {
  width: calc(100% - 2px) !important;
  min-width: 829px !important;
  :global .chakra-tabs__tab-panel {
    padding: 0 !important;
  }
}

.document-overview__trainingContainer {
  min-height: 70vh;
}

:global(.cc-presence-list) {
  display: none;
}
