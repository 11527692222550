.generate-ta-app-container {
  width: 100%;
}

.generate-ta-component {
  margin-bottom: 40px;
}

.generate-ta-component li {
  margin-bottom: 5px;
  margin-left: 20px;
}

.generate-ta-component div {
  margin-top: 5px;
}

.generate-ta-component h1 {
  font-size: 1.4em;
}
.generate-ta-component h3 {
  font-size: 1.2em;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 10px;
}
