.dropzone-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 8.5rem;
  border-radius: 4px;
  background-color: var(--chakra-colors-gray-50);
  border: 1px solid var(--chakra-colors-gray-400);
  margin-block: 24px;
}

.dropzone-upload-action {
  color: #3d4cf2;
  cursor: pointer;
}

.dropzone-upload-label {
  font-size: 14px;
  text-align: center;
  flex-grow: 0.33;
}

.dropzone-file-input {
  display: none !important; /* Need to override bootstrap display: block */
}
