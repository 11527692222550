.file-document-previewer__wrapper-sidebar {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}

.file-preview-info-text {
  padding: 10px 10px 25px;
  text-align: center;
}
