.autosave-notification {
  display: flex;
  align-self: center;
  gap: 4px;
  align-items: center;
  color: var(--chakra-colors-gray-500);
  font-size: 12px;
  min-width: 16px;
  svg {
    color: var(--chakra-colors-green-500);
  }
}

.autosave-notification__fix-width {
  width: 108px;
  min-height: 18px;
}
