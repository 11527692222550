:scope {
  --insertion-color: var(--chakra-colors-green-100);
  --deletion-color: var(--chakra-colors-red-100);
}

:local(ins) {
  background-color: var(--insertion-color);

  img {
    border: dotted var(--insertion-color) medium;
    padding: 4px;
  }

  * {
    background: var(--insertion-color) !important;
  }
}

:local(del) {
  text-decoration: none;
  background-color: var(--deletion-color);

  img {
    border: solid var(--deletion-color) medium !important;
    padding: 4px;
  }

  * {
    background: var(--deletion-color) !important;
  }
}

:local(ins),
:local(del) {
  text-decoration: none;

  ol {
    padding-left: 2em !important;
  }

  /* Targets immediate section list children; they need to be padded slightly more than ordered lists */
  > ol[multilevel] {
    padding-left: 3.3em !important;
  }
}
