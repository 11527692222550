.document__status-banner {
  width: 100%;
  height: 20px;
}

.document__status-banner-edit {
  width: 100%;
  height: 20px;
  background: var(--chakra-colors-blue-100);
  color: var(--chakra-colors-blue-900);
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.document__status-banner-suggest {
  width: 100%;
  height: 20px;
  background: var(--chakra-colors-orange-100);
  color: var(--chakra-colors-orange-900);
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
