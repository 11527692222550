.file-document__pdf-wrapper-comments {
  width: 73%;
  height: auto;
  overflow: hidden;
  padding-top: 16px;
}

.file-document__pdf-wrapper-comments:global(.narrow-sidebar) {
  width: 90%;
  height: auto;
  overflow: hidden;
  padding-top: 16px;
}

.file-document__pdf-wrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding-top: 16px;
}

.react-pdf-element {
  overflow: auto;
}
