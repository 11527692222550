.file-document__image-wrapper-comments {
  width: 73%;
  height: auto;
  overflow: hidden;
  text-align: center;
  padding-top: 16px;
}

.file-document__image-wrapper-comments:global(.narrow-sidebar) {
  width: 90%;
  height: auto;
  overflow: scroll;
  text-align: center;
  padding-top: 16px;
}

.file-document__image-wrapper {
  width: 100%;
  height: auto;
  overflow: scroll;
  text-align: center;
  padding-top: 16px;
}

.file-document__image-wrapper-comments img {
  max-width: 100%;
  margin: auto;
  display: block;
}

.file-document__image-wrapper img {
  max-width: 100%;
  margin: auto;
  display: block;
}
