@import './config/DocumentEditorStyleConfig.module.css';

:root {
  --ck-color-comment-remove-background: hsl(120deg, 100%, 90%) !important;
  --qualio-font-family: 'Open Sans', 'Noto Sans', 'Noto Emoji', 'Noto Sans Math',
    'Noto Sans JP', 'Noto Sans KR', 'Noto Sans SC', sans-serif;
}
.document-editor__app {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.document-editor__app__align {
  align-items: center;
}

/* CKE AI Popup widget: hide robot icon */
:global div[aria-label='AI Assistant'] .ck-form__header > svg:first-child {
  display: none;
}

/* CKE AI Popup widget: hide open text prompt */
:global div[aria-label='AI Assistant'] .ck.ck-search.ck-autocomplete {
  display: none;
}

/* CKE AI Popup widget: button styling */
:global div.ck-ai-form__toolbar button {
  border-radius: 4px !important;
  color: #3d4cf2 !important;
  border: 1px solid #7883ff !important;
}
:global div.ck-ai-form__toolbar button.ck-button-action {
  background: #3d4cf2 !important;
  color: #fff !important;
  border: none !important;
}

.document-editor__app :global .ck.ck-toolbar_grouping {
  width: 100%;
  background: white;
  border: none;
  box-shadow: 0 8px 4px -4px rgb(0 0 0 / 10%);
  margin-top: 0px;
  margin-bottom: 5px;
}

.document-editor__app :global .ck.ck-dropdown__panel .ck-toolbar {
  background: white;
}

.document-editor__app :global div.ck.ck-heading-dropdown ul.ck-list {
  font-size: 14px;
  font-family: var(--qualio-font-family);
}

.document-editor__app
  :global
  button.ck.ck-button.ck-heading_p.ck-button_with-text
  span.ck-button__label {
  font-size: 1em;
}

.document-editor__app
  :global
  button.ck.ck-button.ck-heading_h1.ck-button_with-text
  span.ck-button__label {
  font-size: 1.4em !important;
  font-weight: 600;
}

.document-editor__app
  :global
  button.ck.ck-button.ck-heading_h2.ck-button_with-text
  span.ck-button__label {
  font-size: 1.3em;
  font-weight: 600;
}

.document-editor__app
  :global
  button.ck.ck-button.ck-heading_h3.ck-button_with-text
  span.ck-button__label {
  font-size: 1.2em;
  font-weight: 600;
}

.document-editor__app
  :global
  button.ck.ck-button.ck-heading_h4.ck-button_with-text
  span.ck-button__label {
  font-size: 1.2em;
  font-style: italic;
  font-weight: 600;
}

:global(.ck-content > p) {
  line-height: 1.42857143 !important;
}

.document-editor__app :global h1.section-heading {
  padding-top: 20px !important;
  padding-bottom: 10px;
}

/* Adjusts lists in tables inside editors inside QTheme */
.document-editor__app chakra-scope td > ul,
.document-editor__app chakra-scope td > ol,
/* Adjusts lists in the generated change control ai side panel */
:global(.suggestion-sections ul),
:global(.suggestion-sections ol) {
  padding-left: 3.3em;
}

.document-editor__app :global ol li a {
  color: rgb(0, 105, 255) !important;
  padding: 0 !important;
  margin: 0 !important;
  border-bottom: unset !important;
}

.document-editor__app :global ol li a:focus {
  padding: 0 !important;
  margin: 0 !important;
}

.document-editor__app :global ul li a:focus {
  padding: 0 !important;
  margin: 0 !important;
}

.document-editor__app :global ul li a {
  color: rgb(0, 105, 255) !important;
  padding: 0 !important;
  margin: 0 !important;
  border-bottom: unset !important;
}

.document-editor__app :global ol li a:hover {
  text-decoration: underline !important;
  background-color: unset !important;
  border-bottom: unset !important;
  color: unset !important;
  cursor: pointer !important;
}

.document-editor__app :global ul li a:hover {
  text-decoration: underline !important;
  background-color: unset !important;
  border-bottom: unset !important;
  cursor: pointer !important;
  padding-inline: unset !important;
  color: #004ab3 !important;
}

.document-editor__app :global a[href='#']:hover {
  text-decoration: none !important;
}

.document-editor__app :global a[href='error'] {
  color: #93949f !important;
  font-style: italic;
  text-decoration: line-through;
  cursor: default !important;
}

/* For making videos and other embedded content playable */
.document-editor__app
  :global(
    .ck-editor__editable:not(.ck-read-only)
      .ck-widget_selected
      .ck-media__wrapper
      > :not(.ck-media__placeholder)
  ) {
  pointer-events: initial !important;
}

/* we do not want to apply this rule if the editable areas is nested in .ck-editor__main as this is the classic editor text area that we want to render with border */
.document-editor__app
  :global(
    :not(.ck-editor__main)
      > .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused
  ),
:global(:not(.ck-editor__main) > .ck.ck-editor__editable_inline) {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.document-editor__app
  :global
  .ck.ck-editor__editable_inline
  pre[data-language]:after {
  content: none;
}

.document-editor__app :global .ck.ck-editor__editable_inline p {
  margin-top: 0;
}

.document-editor__app
  :global
  .ck.ck-editor__editable
  > figure.table:first-child {
  margin-top: 25px;
}

.document-editor__app :global .ck.ck-editor__editable_inline p a {
  cursor: pointer;
}

.document-editor__app :global .ck.ck-editor__editable_inline a span {
  color: rgb(0, 105, 255) !important;
}

:global(label.chakra-radio) {
  margin: 0;
}

:global(input[type='checkbox']) {
  margin: 0 !important;
  margin-right: 4px !important;
  vertical-align: middle;
}

:global(.ck-content blockquote) {
  padding-inline: 1.5em !important;
  padding-top: 0.4em !important;
  padding-bottom: 0 !important;
  margin-inline: 0 !important;
  margin-top: 0 !important;
  margin-bottom: var(--DocumentParagraphPaddingBottom) !important;
  border-left: 5px solid #ccc !important;
}

:global(.ck-content blockquote p) {
  font-size: 14px !important;
}

:global(.ck.ck-editor__editable .table .ck-table-column-resizer) {
  top: 0 !important;
  bottom: 0 !important;
}

:global(.ck .ck-user__marker) {
  position: absolute !important;
}

:global(.ck-sidebar--wide .ck-sidebar-item:last-child) {
  padding-bottom: 20px;
}

:global(html, body) {
  scroll-behavior: smooth;
}

:global(.ck-content a) {
  cursor: pointer;
}

:global(.ck-button.ck-comment--remove) {
  background-color: transparent !important;
}

/********** Logic to restrict user interaction in comment-only mode ***************/

:global(.non-editable-mode) {
  cursor: default;
}

:global(.non-editable-mode .section-heading .locked-section-headers) {
  cursor: pointer;
  pointer-events: none;
}

:global(.section-heading__hide) {
  /*the element is hidden but accessible, so that the section counter is still incremented*/
  position: absolute;
  left: -9999px;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
}

:global(.section-heading__placeholder) {
  color: #93949f;
}

:global(.section-heading__placeholder .fa-eye-slash) {
  vertical-align: top;
  padding-inline: 5px;
}

:global(.non-editable-mode input) {
  pointer-events: none;
}

:global(input.non-editable-mode-wrapper) {
  pointer-events: none;
}

:global(.non-editable-mode figure) {
  outline: none !important;
}

:global(.non-editable-mode figure:not(.ck-comment-marker)) {
  border: none !important;
}

:global(.non-editable-mode figure > img) {
  border: none !important;
  outline: none;
}

:global(.non-editable-mode figure > div.ck-widget__type-around) {
  display: none !important;
  border: none !important;
  outline: none;
  pointer-events: none !important;
}

:global(figure > div.ck-widget__type-around) {
  display: block !important;
}

:global(.non-editable-mode figure > div.ck-widget__resizer) {
  display: none !important;
}

:global(.non-editable-mode figure > div.ck-widget__selection-handle) {
  display: none !important;
}

:global(figure.ck-widget) {
  transition: none !important;
}

:global(.non-editable-mode td.ck-editor__nested-editable_focused),
:global(.non-editable-mode td.ck-editor__nested-editable:focus) {
  background: none !important;
  border-style: inherit !important;
  outline: inherit !important;
  outline-offset: inherit !important;
  border: inherit !important;
  box-shadow: inherit !important;
}

:global(.non-editable-mode td.ck-editor__editable_selected) {
  background: none !important;
  outline: inherit !important;
  outline-offset: inherit !important;
  box-shadow: inherit !important;
}

:global(.non-editable-mode span.image-inline) {
  outline: none !important;
}

:global(.non-editable-mode span.image-inline:not(.ck-comment-marker)) {
  border: none;
}

:global(.non-editable-mode span.image-inline div.ck-widget__resizer) {
  display: none !important;
}

:global(.document-layout-inner) {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

:global(.footer-row) {
  padding-bottom: 0;
  padding-top: 0;
}

:global(.toolbarWrapper) {
  background-color: white;
  width: 100%;
  max-width: 100%;
  z-index: var(--ToolbarWrapperZIndex);
  position: sticky;
  padding-top: 0;
  top: 0;
}

:global(#contentView:has(.document-container__inner)) {
  margin-top: unset !important;
}

:global(.document-editor-overview-refresh .gray-container-outer) {
  padding-top: unset !important;
}

/********** Compare against CSS ***************/

:global(.doc-diff-deletion) {
  display: inline-block;
  background-color: #fddcda;
}

:global(.doc-diff-addition) {
  display: inline-block;
  background-color: #e1ffd3;
}

:global(.doc-diff-replacement) {
  display: inline-block;
  background-color: #e7ebff;
}

:global(.ck .ck-user__marker.ck-user__marker_hovered .ck-user__marker-tooltip) {
  font-family: var(--chakra-fonts-body);
}

:global(.mention__item) {
  font-family: var(--qualio-font-family) !important;
}

:global(.mention__item:before) {
  display: inline-block;
  content: '';
  background-size: 16px 16px;
  height: 16px;
  width: 16px;
  transform: translateY(20%);
  margin-right: 8px;
  margin-left: 4px;
}

:global(.mention__item_user:before) {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' rx='8' fill='%23E6E7EB'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 4.667a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5Zm-2.083 1.25a2.083 2.083 0 1 1 4.166 0 2.083 2.083 0 0 1-4.166 0ZM4.86 9.444c.39-.391.92-.61 1.473-.61h3.334a2.083 2.083 0 0 1 2.083 2.083v.833a.417.417 0 1 1-.833 0v-.833a1.25 1.25 0 0 0-1.25-1.25H6.333a1.25 1.25 0 0 0-1.25 1.25v.833a.417.417 0 1 1-.833 0v-.833c0-.553.22-1.083.61-1.473Z' fill='%2314151A'/%3E%3C/svg%3E");
}

:global(button.mention__item_user_btn.ck-button.ck-on) {
  background-color: var(--chakra-colors-blue-500) !important;
}

:global(.ck-on .mention__item_user) {
  color: var(--chakra-colors-white);
}

:global(.mention__user) {
  font-weight: bold;
  color: revert !important;
  background-color: revert !important;
}
