.file-document__sidebar {
  padding-top: 16px;
  width: 27%;
  max-width: 280px;
  padding-left: 5px;
}

.file-document__sidebar:global(.narrow) {
  width: 10%;
}

.file-document__sidebar:global(.narrow) :global .ck-sidebar-item {
  justify-content: end !important;
}

.file-preview-info-text {
  padding: 10px 10px 25px;
  text-align: center;
}
