.file-preview-placeholder {
  padding: 10px 10px 25px;
  text-align: center;
}

.file-upload-editor {
  width: 100%;
  min-height: 70vh;
}

.file-document-editor__preview-placeholder {
  width: 73%;
  height: auto;
  overflow: hidden;
}

.file-document-editor__preview-placeholder:global(.narrow-sidebar) {
  width: 90%;
  height: auto;
  overflow: hidden;
}

.file-document-editor__preview-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.one-drive-file-actions-container {
  display: flex;
  gap: 8px;
}
