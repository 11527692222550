:root {
  /*
    Page dimensions (A4) and color
  */

  --A4PageWidth: 210mm;
  --A4PageHeight: 297mm;
  --DocumentPageInlineMargin: 20mm;
  --DocumentInlinePadding: 2mm;
  --DocumentEditorPageBackground: white;

  /*
    Text paragraph
  */
  --DocumentParagraphPaddingBottom: 10px;

  /*
    Toolbar Wrapper
   */
  --ToolbarWrapperZIndex: 1001;
}
