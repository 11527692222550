@import '../../../config/DocumentEditorStyleConfig.module.css';

.document-section-content {
  width: var(--A4PageWidth);
  min-height: var(--A4PageHeight);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: var(--DocumentPageInlineMargin);
  height: 100%;
  background-color: var(--DocumentEditorPageBackground);
}

.document-section-content :global h1.section-heading {
  padding-inline: var(--DocumentInlinePadding);
  font-size: 1.6em;
  clear: both;
}

.document-section-content :global .ck.ck-editor__editable_inline {
  /* subtract 20mm margin on the left and right */
  width: calc(var(--A4PageWidth) - 2 * var(--DocumentPageInlineMargin));
  padding-inline: var(--DocumentInlinePadding);
  height: auto;
  background-color: var(--DocumentEditorPageBackground);
  overflow: hidden;
}

.document-section-content:global(.landscape)
  :global(.ck.ck-editor__editable_inline) {
  width: var(--A4PageHeight);
}

.document-section-content :global .ck.ck-editor__editable_inline p {
  margin-bottom: var(--DocumentParagraphPaddingBottom) !important;
}

.document-section-content :global .ck.ck-editor__editable_inline .table p {
  margin-bottom: 0 !important;
}

.document-section-content :global(figure table) {
  margin: 0 !important;
}

.document-section-content :global(img) {
  display: inline-flex;
  align-items: flex-start;
  vertical-align: inherit;
}

.document-section-content :global .ck-content .image.image_resized {
  max-width: 98% !important;
}

.document-section-content :global .ck-content figure.table figure.image > img {
  max-width: 100% !important;
}

.document-section-content
  :global
  .ck.ck-editor__editable_inline
  pre[data-language]:after {
  content: none;
}

.document-section-content :global .locked-section-headers {
  cursor: not-allowed;
}

.document-section-content :global h1 {
  font-size: 1.4em;
}

.document-sidebar {
  max-width: 332px;
  width: 100%;
  padding: 0 8px;
  margin-top: 56px;
}

.document-sidebar :global(.ck.ck-sidebar--narrow .ck-sidebar-item) {
  justify-content: right;
}

.document-sidebar:global(.narrow) {
  max-width: 332px;
  padding: 0 8px;
}

.document-toc-container {
  max-width: 332px;
  width: 100%;
  padding: 16px 8px;
}

.document-comment-control p {
  position: relative;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.document-content-container {
  position: relative;
  min-height: 70vh;
}

.document-editor__document__content-html-view-container {
  position: relative;
  min-height: 70vh;
  min-width: calc(var(--A4PageWidth) + 144px);
}

:global(.transition_on_click .ck.ck-annotation-wrapper--active) {
  position: relative;
  left: -9px;
}

.document-sidebar :global .ck.ck-presence-list:global {
  display: none;
}

:global .ck-button_with-text.ck-comment__input-actions--submit:global {
  background: #0069ff !important;
  color: white !important;
  margin: 2px !important;
  padding: 0 16px;
  height: 22px;
}

:global .ck-button_with-text.ck-comment__input-actions--submit > svg:global {
  display: none;
}

:global .ck-button_with-text.ck-comment__input-actions--cancel > svg:global {
  display: none;
}

:global .ck-button_with-text.ck-comment__input-actions--cancel:global {
  background: #f9f9fa !important;
  border-radius: 4px;
  border: 1px #0069ff solid !important;
  color: #0069ff !important;
  height: 22px;
  margin: 2px !important;
}

.document-content-container :global .ck.ck-editor {
  width: 100%;
  max-width: 700px;
}

.document-content-container :global .non-editable-mode {
  caret-color: transparent;
}

.document-content-container :global .todo-list .todo-list__label > input:focus {
  outline: none;
}

.document-content-container :global .todo-list li {
  margin-top: 0 !important;
}

.document-content-container :global(.progress-indication::before) {
  content: attr(percentage) '%';
  display: inline-flex;
  vertical-align: top;
  -webkit-box-align: center;
  align-items: center;
  max-width: 100%;
  font-weight: 600;
  line-height: 1.2;
  outline: transparent solid 2px;
  outline-offset: 2px;
  min-height: 20px;
  min-width: 20px;
  font-size: 12px;
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  border-radius: 10px;
  color: #2e2e33;
  background: #f2f3f5;
  box-shadow: #e3e3e5 0 0 0 1px inset;
  margin-inline: 5px;
}

.document-content-container :global(.upload-complete-icon) {
  display: inline !important;
  right: unset !important;
  top: unset !important;
  margin-inline: 5px;
  animation-duration: 2s, 2s !important;
}

:global(.ck) {
  --ck-user-avatar-background: var(--chakra-colors-blue-900);
}

/* hide the counter for number of users present on doc */
:global(.ck.ck-presence-list__counter) {
  display: none;
}

:global(.ck.ck-presence-list__list-item) {
  --ck-user-avatar-size: 30px;
}

:global(.ck.ck-presence-list__balloon) {
  --ck-user-avatar-size: 20px;

  --ck-color-presence-list-dropdown-background: var(--chakra-colors-gray-50);
  --ck-color-presence-list-dropdown-arrow-border: var(--chakra-colors-gray-200);
}

:global(.ck.ck-user__name) {
  font-family: 'Open Sans';
  --ck-font-face: 'Open Sans';
}

:global(.ck-presence-list__dropdown-list .ck-user__name) {
  font-size: 8px;
}
