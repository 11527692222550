.bulk-upload-container {
  width: 100%;
}

.bulk-upload-container__doc-code-tag {
  padding-top: 6px;
}

label[for='documentType'] {
  display: inline !important;
}

label[for='documentType'] ~ div {
  padding-top: 8px;
}

a svg {
  vertical-align: text-top;
}

.empty-uploaded-files-list {
  text-align: center;
}
