.loading-spinner-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.loading-spinner-wrapper p {
  text-align: center;
}

.loading-spinner-wrapper div {
  display: flex;
  justify-content: center;
}
